import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  userCompany,
  Role,
  User,
  Category,
  CompanyForm,
  PortalMapping,
  CashFlowMapping,
} from "./user.types";
import {
  chooseCompany,
  createPassword,
  fetchComanies,
  fetchFAQ,
  fetchUserList,
  init,
  inviteUser,
  login,
  resetPassword,
  updateDefaultCompany,
  updateUser,
  userInfoUpdate,
} from "./userAction";
import {
  deleteDataToStorage,
  LANGUAGE,
  SELECTED_COMPANY,
  setDataToStorage,
  STORAGE_USER_DETAIL,
} from "../../serveces/storage";
import { BottomSheetType, Languages } from "../../enums/enum";
import moment from "moment";
import { LoaderType } from "../type";

const getUserFromLocalStorage = () => {
  const storedUser = localStorage.getItem(STORAGE_USER_DETAIL);
  const storedCompany = localStorage.getItem(SELECTED_COMPANY);
  const storedLanguage = localStorage.getItem(LANGUAGE);

  return {
    user: storedUser ? JSON.parse(storedUser) : null,
    company: storedCompany ? JSON.parse(storedCompany) : null,
    language: storedLanguage ? JSON.parse(storedLanguage) : Languages.En,
  };
};

const { user, company, language } = getUserFromLocalStorage();

export type UserState = {
  user: User | null;
  companies: Array<userCompany>;
  selectedCompany: userCompany | null;
  userRoles: Array<Role>;
  companyCategories: Array<Category>;
  rePortalMappings: Array<PortalMapping>;
  accountMappingCashFlows: Array<CashFlowMapping>;
  companyForms: Array<CompanyForm>;
  language: Languages;
  companyData: {
    count: number;
    items: Array<User>;
    page: number;
    pageSize: number;
  };
  hasError: boolean;
  showSuccessMessage: boolean;
  FAQ: Array<any>;
  bottomSheet: BottomSheetType;
  errorMessage: string;
  loading: LoaderType;
};

const initialState: UserState = {
  user,
  companies: [],
  selectedCompany: company,
  userRoles: [],
  companyCategories: [],
  rePortalMappings: [],
  accountMappingCashFlows: [],
  companyData: { count: 0, items: [], page: 0, pageSize: 20 },
  companyForms: [],
  hasError: false,
  language: language,
  showSuccessMessage: false,
  FAQ: [],
  bottomSheet: BottomSheetType.IDLE,
  errorMessage: "",
  loading: "idle",
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    selectCompany: (state, { payload }) => {
      const company = state.companies.find(
        (item: userCompany) => item.id === payload.selectedCompanyId
      );
      if (company) {
        state.selectedCompany = company;
        setDataToStorage(SELECTED_COMPANY, JSON.stringify(company));
      }
    },
    showSuccessMessage: (state, { payload }) => {
      state.showSuccessMessage = payload;
    },
    showErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setBottomSheet: (state, { payload }) => {
      state.bottomSheet = payload as BottomSheetType;
    },
    updateLanguage: (state, { payload }) => {
      setDataToStorage(LANGUAGE, JSON.stringify(payload));
      moment.locale(payload === "ge" ? "ka" : "en-gb");
      state.language = payload;
    },
    updateUserTokens: (state, { payload }) => {
      const { token, refreshToken } = payload;
      if (state.user) {
        state.user = { ...state.user, token, refreshToken };
      }
    },
    logout: (state) => {
      state.user = null;
      void deleteDataToStorage(SELECTED_COMPANY);
      void deleteDataToStorage(STORAGE_USER_DETAIL);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
    // Login
    builder
      .addCase(login.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        if (!payload.hasToChangePassword) {
          state.user = payload;
          state.selectedCompany = payload.defaultCompany;
          setDataToStorage(
            SELECTED_COMPANY,
            JSON.stringify(payload.defaultCompany)
          );
          setDataToStorage(STORAGE_USER_DETAIL, JSON.stringify(payload));
        }
        state.loading = "succeeded";
      })
      .addCase(login.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      });

    // resetPassword
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(resetPassword.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });

    // createPassword
    builder
      .addCase(createPassword.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        createPassword.fulfilled,
        (state, { payload }: PayloadAction<User>) => {
          state.user = payload;
          state.selectedCompany = payload.defaultCompany;
          setDataToStorage(
            SELECTED_COMPANY,
            JSON.stringify(payload.defaultCompany)
          );
          setDataToStorage(STORAGE_USER_DETAIL, JSON.stringify(payload));
          state.loading = "succeeded";
        }
      )
      .addCase(createPassword.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });

    // choose Company
    builder
      .addCase(chooseCompany.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        chooseCompany.fulfilled,
        (state, { payload }: PayloadAction<User>) => {
          state.loading = "succeeded";
          if (state.user) {
            const updatedUser = {
              ...state.user,
              clientRoleId: payload.clientRoleId,
              token: payload.token,
              refreshToken: payload.refreshToken,
              permissions: payload.permissions,
            };
            state.user = updatedUser;
            setDataToStorage(STORAGE_USER_DETAIL, JSON.stringify(updatedUser));
          }
        }
      )
      .addCase(chooseCompany.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });

    builder
      .addCase(updateDefaultCompany.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        updateDefaultCompany.fulfilled,
        (
          state,
          { payload }: PayloadAction<{ defaultCompanyId: number | null }>
        ) => {
          state.loading = "succeeded";

          const stateDefaultCompanyId = payload.defaultCompanyId;

          if (state.user) {
            const defaultCompany = state.companies.find(
              (element) => element.id === stateDefaultCompanyId
            );
            const updatedUser = {
              ...state.user,
              defaultCompanyId: payload.defaultCompanyId,
              defaultCompany: defaultCompany || null,
            };

            state.user = updatedUser;
            setDataToStorage(STORAGE_USER_DETAIL, JSON.stringify(updatedUser));
          }
        }
      )
      .addCase(updateDefaultCompany.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });
    builder
      .addCase(init.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(init.fulfilled, (state, { payload }) => {
        state.userRoles = payload.roles;
        state.companyCategories = payload.companyCategories;
        state.companyForms = payload.companyForms;
        state.rePortalMappings = payload.rePortalMappings;
        state.accountMappingCashFlows = payload.accountMappingCashFlows;
        state.loading = "succeeded";
      })
      .addCase(init.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });

    //fetchUserList
    builder
      .addCase(fetchUserList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchUserList.fulfilled, (state, { payload }) => {
        state.companyData = payload;
        state.loading = "succeeded";
      })
      .addCase(fetchUserList.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });
    // updateUser
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        const index = state.companyData.items.findIndex(
          (user) => user.id === payload.userId
        );
        if (index > -1) {
          state.companyData.items[index].clientCompany = payload.clientCompany;
        }
        state.loading = "succeeded";
      })
      .addCase(updateUser.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });
    // inviteUser
    builder
      .addCase(inviteUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(inviteUser.fulfilled, (state, { payload }) => {
        const updateUsers = [payload, ...state.companyData.items];
        state.companyData.items = updateUsers;
        state.loading = "succeeded";
      })
      .addCase(inviteUser.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });
    // userInfoUpdate
    builder
      .addCase(userInfoUpdate.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(userInfoUpdate.fulfilled, (state, { payload }) => {
        const updateUser = {
          ...state.user,
          firstName: payload.firstName,
          lastName: payload.lastName,
          mobile: payload.mobile,
          email: payload.email,
        } as User;
        state.user = updateUser;
        setDataToStorage(STORAGE_USER_DETAIL, JSON.stringify(updateUser));
        state.loading = "succeeded";
      })
      .addCase(userInfoUpdate.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });
    //fetchFAQ
    builder
      .addCase(fetchFAQ.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchFAQ.fulfilled, (state, { payload }) => {
        state.FAQ = payload;
        state.loading = "succeeded";
      })
      .addCase(fetchFAQ.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      })
    //fetchComanies
    builder
      .addCase(fetchComanies.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchComanies.fulfilled, (state, { payload }) => {
        state.companies = payload;
        state.loading = "succeeded";
      })
      .addCase(fetchComanies.rejected, (state, { error }) => {
        state.loading = "failed";
        state.hasError = true;
      });
  },
});

export default userSlice.reducer;

export const {
  selectCompany,
  updateLanguage,
  setBottomSheet,
  showSuccessMessage,
  showErrorMessage,
  logout,
} = userSlice.actions;
