import { PeriodType } from "../enums/enum";
import {
  CashFlowCategoryType,
  CashFlowDirectionType,
} from "../store/dashboard/dahsboard.enums";
import {
  CashFlow,
  CashFlowRecord,
  FinancialInDetailRecord,
  FinancialRecord,
  NetIncomeResponseRecord,
} from "../store/dashboard/dashboard.types";

import { formatDateByPeriod } from "./helper";

export const getUniquePeriods = (
  data: Array<FinancialRecord | NetIncomeResponseRecord | CashFlowRecord>
): string[] => {
  return Array.from(new Set(data.map((item) => item.period)));
};


export type GridDataItem = {
  id: number | string;
  title: string;
  label?: string;
  percentage?: boolean;
  data: { [key: string]: number | string; period: string }[];
};




// old version
export interface BarChartDataRecord {
  data: Array<{ label: string;[key: string]: number | string }>;
  labels: Array<string>;
}

// CashFlow
export const generateCashFlowGroupedChartDataForGroups = (
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  const groupedData: { [key: string]: any } = {};

  data.forEach((cashFlowItem) => {
    const { period, cashFlowCategory, cashFlowDirection, amount } =
      cashFlowItem;
    if (!groupedData[formatDateByPeriod(period, periodType)])
      groupedData[formatDateByPeriod(period, periodType)] = {
        period: formatDateByPeriod(period, periodType),
      };

    const directionKey =
      cashFlowDirection === CashFlowDirectionType.Received
        ? "Received"
        : "Paid";
    const categoryKey = CashFlowCategoryType[cashFlowCategory];

    groupedData[formatDateByPeriod(period, periodType)][
      `${directionKey}-${categoryKey}`
    ] = amount;
  });
  return Object.values(groupedData);
};

// cashFlow
export const generateCashFlowOutcome = (
  keys: Array<string>,
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          ["Net Cash Flow"]:
            item.cashFlowDirection === CashFlowDirectionType.Received
              ? item.amount
              : -item.amount,
        };
      } else {
        barData[item.period]["Net Cash Flow"] =
          item.cashFlowDirection === CashFlowDirectionType.Received
            ? barData[item.period]["Net Cash Flow"] + item.amount
            : barData[item.period]["Net Cash Flow"] - item.amount;
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const formatNumber = (sum: number) =>
  sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getMonthlyPeriods = (
  fromDate: string,
  toDate: string
): string[] => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  const result: string[] = [];

  const current = new Date(start.getFullYear(), start.getMonth(), 1);

  while (current <= end) {
    const month = current.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    result.push(`${current.getFullYear()}-${formattedMonth}`);
    current.setMonth(current.getMonth() + 1);
  }

  return result;
};

export const processAccountData = (
  data: Array<FinancialInDetailRecord>
): Record<string, number> => {
  // Sum totals for each accountId
  const totals: Record<string, number> = {};
  data.forEach((entry) => {
    if (!totals[entry.accountId]) {
      totals[entry.accountId] = 0;
    }
    totals[entry.accountId] += entry.total;
  });

  // Rank accountIds based on their totals
  const sortedAccountIds = Object.entries(totals)
    .sort(([, totalA], [, totalB]) => totalB - totalA)
    .map(([accountId]) => accountId);

  // Assign ranks
  const ranking: Record<string, number> = {};
  sortedAccountIds.forEach((accountId, index) => {
    ranking[accountId] = index + 1;
  });

  return ranking;
};
