import { PeriodType } from "../enums/enum";
import {
  FinancialRecord,
  NetIncomeResponseRecord,
} from "../store/dashboard/dashboard.types";
import { GridDataItem } from "./dashboardHelper";
import { formatDateByPeriod } from "./helper";


export const sumOfGroup = (array: string[]): number => {
  return array.reduce((sum, value) => sum + +parseFloat(value).toFixed(0), 0);
};


export const sumRevenueExpenses = (array: Array<FinancialRecord>): number => {
  return Number(
    array.reduce((sum, item) => sum + item.sumAmount, 0).toFixed(1)
  );
};

export const sumNetIncome = (array: Array<NetIncomeResponseRecord>): number => {
  return Number(
    array
      .reduce((sum, item) => sum + item.totalProfit - item.totalExpense, 0)
      .toFixed(1)
  );
};


export const sumRevenueExpensesWithName = (
  array: Array<FinancialRecord>,
  groupName: string
): number => {
  return Number(
    array
      .reduce(
        (sum, item) => (item.name === groupName ? sum + item.sumAmount : sum),
        0
      )
      .toFixed(1)
  );
};

type OutputData = {
  period: string;
} & {
  [key: string]: number | string;
};

export const generateOrderedStackChartData = (
  data: Array<FinancialRecord>,
  periodType: PeriodType
): OutputData[] => {
  const grouped = data.reduce<Record<string, OutputData>>((acc, item) => {
    const { period, name, sumAmount } = item;
    const formatPeriod = formatDateByPeriod(period, periodType);
    acc[formatPeriod] = acc[formatPeriod] || { period: formatPeriod };
    acc[formatPeriod][name] = sumAmount;
    return acc;
  }, {});

  return Object.values(grouped);
};

export const getTableLabels = (
  data: Array<FinancialRecord>
): Array<{ id: number; name: string }> => {
  return Array.from(
    new Map(
      data.map((item) => [
        item.name,
        { id: item.id, ordering: item.ordering, parentOrdering: item.parentOrdering },
      ])
    ).entries()
  )
    .sort(
      (a, b) =>
        a[1].parentOrdering - b[1].parentOrdering ||
        a[1].ordering - b[1].ordering
    )
    .map(([name, record]) => ({
      name,
      id: Number(record.id),
    }));
};


export const getUniqueLabels = (data: Array<FinancialRecord>): string[] => {
  return Array.from(
    new Map(data.map((item) => [item.name, { ordering: item.ordering, parentOrdering: item.parentOrdering }])).entries()
  )
    .sort((a, b) =>
      a[1].parentOrdering - b[1].parentOrdering || a[1].ordering - b[1].ordering
    )
    .map(([name]) => name);
};


export const getTreeChartData = (
  data: Array<FinancialRecord>,
  colors: Array<string>
): Array<{ name: string; value: number; netValue: number; color: string }> => {
  const totalSum = data.reduce((total, item) => total + item.sumAmount, 0);

  // Summarize data by mappingGroupName with their total sum
  const summary = data.reduce<
    Record<string, { value: number; ordering: number }>
  >((acc, { name, sumAmount, ordering }) => {
    if (!acc[name]) {
      acc[name] = {
        value: 0,
        ordering: ordering,
      };
    }
    acc[name].value += sumAmount;
    return acc;
  }, {});

  // Convert to array, sort by ordering, and map to final output
  return Object.entries(summary)
    .sort(([, a], [, b]) => a.ordering - b.ordering) // Sort by accountMappingGroupOrdering
    .map(([name, { value }], index) => {
      const percentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
      return {
        name,
        value: parseFloat(percentage.toFixed(2)) || 0,
        netValue: parseFloat(value.toFixed(2)),
        color: colors[index % colors.length], // Handle cases where colors array is shorter
      };
    });
};

export const generateTableData = (
  labels: Array<string>,
  data: Array<FinancialRecord>
): Array<Array<string>> => {
  return labels.map((label) => {
    return data
      .filter((dataItem) => dataItem.name === label)
      .map((dataItem) => dataItem.sumAmount.toFixed(1));
  });
};

export function generateGridData(
  data: Array<FinancialRecord>,
  periodType: PeriodType
): GridDataItem[] {
  const groupedData: Record<
    string,
    { [key: string]: number | string; period: string; id: number | string }[]
  > = {};

  console.log('data', data, periodType);

  const sortedData = [...data].sort((a, b) => a.parentOrdering - b.parentOrdering || a.ordering - b.ordering);

  sortedData.forEach((item) => {
    const { id, name, sumAmount, period } = item;
    if (!groupedData[name]) {
      groupedData[name] = [];
    }
    groupedData[name].push({
      [name]: sumAmount,
      period: formatDateByPeriod(period, +periodType),
      id,
    });
  });

  return Object.entries(groupedData).map(([title, data]) => ({
    title,
    data,
    id: data[0]?.id || 0,
  }));
}
