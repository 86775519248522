import { isMobile } from "react-device-detect";
import { Treemap, ResponsiveContainer, Tooltip } from "recharts";

import { chartColors } from "../../../../../constants/color";
import { formatNumber } from "../../../../../helpers/dashboardHelper";
import { getTreeChartData } from "../../../../../helpers/profitLossHelper";
import { FinancialRecord } from "../../../../../store/dashboard/dashboard.types";
import { useMemo } from "react";

const CustomTooltip = (props: any) => {
  const { active, payload } = props;

  if (
    active &&
    payload &&
    payload.length &&
    payload.some((el: { value: number }) => el.value)
  ) {
    return (
      <div
        style={
          isMobile
            ? {
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              padding: "10px",
              position: "absolute",
              left: 0,
              minWidth: "max-content",
            }
            : {
              backgroundColor: "#525252",
              borderRadius: 10,
              color: "#FFFFFF",
              fontSize: "13px",
              padding: 0,
              maxHeight: "250px",
              overflowY: "auto",
              width: "450px",
            }
        }
      >
        <div style={{ padding: "10px" }}>
          {payload.map((entry: any, index: number) => {
            if (!entry.value) return null;
            return (
              <div
                key={`tooltip-item-${index}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "5px 0",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      background: `${entry.color}`,
                      borderRadius: "5px",
                      display: "inline-block",
                    }}
                  ></span>
                  <p style={{ color: "#fff", fontSize: "12px" }}>
                    {`${entry.payload.name}: `}
                  </p>
                </div>
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {formatNumber(entry.payload.netValue)} Gel (
                  {formatNumber(+entry.value.toFixed(2))} %)
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

const CustomContent = ({ x, y, width, height, name, value, color }: any) => {
  const gap = 4;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{ fill: "#fff" }}
      />
      <rect
        x={x + gap / 2}
        y={y + gap / 2}
        rx={6}
        ry={6}
        width={width - gap}
        height={height - gap}
        style={{ fill: color, stroke: "#000", strokeWidth: 0 }}
      />
      {name && width > 50 && height > 40 && (
        <>
          <text
            x={x + 5}
            y={y + 25}
            fontSize="14"
            fontWeight="300"
            fill="#fff"
            width={width - gap}
          >
            {name}
          </text>
          <text x={x + 5} y={y + 45} fontWeight="300" fontSize="14" fill="#fff">
            {value}%
          </text>
        </>
      )}
    </g>
  );
};

interface IProps {
  data: Array<FinancialRecord>;
}

const TreeChart = ({ data }: IProps) => {
  const treeData = useMemo(() => getTreeChartData(data, chartColors), [data])

  return (
    <ResponsiveContainer width={"100%"} height={420}>
      <Treemap
        width={400}
        height={200}
        animationDuration={600}
        animationEasing="ease-out"
        data={treeData}
        dataKey="value"
        fill="#8884d8"
        content={<CustomContent />}
      >
        <Tooltip wrapperStyle={{ zIndex: 10 }} content={<CustomTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  );
};

export default TreeChart;
