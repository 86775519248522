import React, { useState } from "react";
import SideBarNavigationItem from "../../desktop/sideBar/sideBarNavigationItem/SideBarNavigationItem";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FAQTitle,
  FAQWrapper,
  NavigationWrapper,
  SideBarFooter,
  Wrapper,
} from "./NavigationMobile.style";

import Icon from "../../shared/icon/Icon";
import { Languages } from "../../../enums/enum";
import { useAppSelector } from "../../../store";
import { checkPermissionForRoute } from "../../../helpers/helper";
import { PageURL } from "../../../constants/router";

import LanguageSwitcherMobile from "../switchLanguage/SwitchLanguageMobile";

interface NavigationMobileProps {
  handleClose: () => void;
  handleChangeLanguage: (lang: Languages) => void;
}
const NavigationMobile = ({
  handleClose,
  handleChangeLanguage,
}: NavigationMobileProps) => {
  const [open] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);

  const checkPermission = (path: string) => {
    if (user) {
      return checkPermissionForRoute(path, PageURL, user.permissions);
    }
    return false;
  };

  return (
    <Wrapper>
      <NavigationWrapper>
        {checkPermission(PageURL.Revenue.path) && (
          <SideBarNavigationItem
            pageName={t("SideBar.Revenue")}
            active={open}
            path={PageURL.Revenue.path}
            iconName={"revenue"}
            handleClose={handleClose}
          />
        )}
        {checkPermission(PageURL.Expenses.path) && (
          <SideBarNavigationItem
            active={open}
            path={PageURL.Expenses.path}
            iconName={"expenses"}
            pageName={t("SideBar.Expenses")}
            handleClose={handleClose}
          />
        )}
        {checkPermission(PageURL.ProfitLoss.path) && (
          <SideBarNavigationItem
            active={open}
            path={PageURL.ProfitLoss.path}
            iconName={"profitLoss"}
            pageName={t("SideBar.ProfitLoss")}
            handleClose={handleClose}
          />
        )}
        {/* {checkPermission(PageURL.CashFlow.path) && (
          <SideBarNavigationItem
            active={open}
            path={PageURL.CashFlow.path}
            iconName={"cashFlow"}
            pageName={t("SideBar.CashFlow")}
            handleClose={handleClose}
          />
        )} */}
        {checkPermission(PageURL.Reportal.path) && (
          <SideBarNavigationItem
            active={open}
            path={PageURL.Reportal.path}
            iconName={"reportal"}
            pageName={t("SideBar.Reportal")}
            handleClose={handleClose}
          />
        )}
        {checkPermission(PageURL.RevenueServices.path) && (
          <SideBarNavigationItem
            active={open}
            path={PageURL.RevenueServices.path}
            iconName={"rsGe"}
            pageName={t("SideBar.RevenueService")}
            handleClose={handleClose}
          />
        )}
        {/* {checkPermission(PageURL.Market.path) && (
          <SideBarNavigationItem
            active={open}
            handleNavigate={() => {
              navigate(PageURL.Market.path);
              handleClose();
            }}
            isSelected={location.pathname === "/app/Market"}
            iconName={"market"}
            pageName={t("SideBar.Market")}
          />
        )} */}
        {/* <SideBarDropDown
          handleClose={handleClose}
          active={open}
          setOpen={setOpen}
        /> */}
      </NavigationWrapper>
      <SideBarFooter>
        <FAQWrapper
          onClick={() => {
            navigate(PageURL.FAQ.path);
            handleClose();
          }}
        >
          <Icon iconName="help" height={17} width={17} />
          <FAQTitle>{t("SideBar.HelpCenter")}</FAQTitle>
        </FAQWrapper>
        <LanguageSwitcherMobile handleChangeLanguage={handleChangeLanguage} />
      </SideBarFooter>
    </Wrapper>
  );
};

export default NavigationMobile;
