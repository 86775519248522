import React from "react";
import { Container, Title, Wrapper } from "./CreateCompany.style";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createCompany } from "../../../store/company/companyAction";

import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../store/user/userSlice";
import { ActionType } from "../../../store/type";
import Input from "../../../components/v2/input/Input";
import Button from "../../../components/v2/button/Button";
import Select from "../../../components/v2/select/Select";
import Icon from "../../../components/shared/icon/Icon";
import { fetchComanies } from "../../../store/user/userAction";

const CreateCompany = () => {
  const loading = useAppSelector((state) => state.company.loading);
  const dispatch = useAppDispatch();
  const initialValues = {
    identificationCode: "",
    name: "",
    email: "",
    city: "",
    address: "",
    mobileCountryCode: "",
    mobile: "",
    companyCategoryId: 0,
    companyFormId: 0,
    postalCode: "",
    integrationType: "",
  };

  const handleSubmit = (
    values: {
      identificationCode: string;
      name: string;
      integrationType: string;
    },
    { resetForm }: { resetForm: () => void }
  ) => {
    const data = {
      identificationCode: values.identificationCode,
      name: values.name,
      email: "admin@amadeo.ge",
      country: "Georgia",
      city: "Tbilisi",
      address: "Tbilisi",
      mobileCountryCode: "995",
      mobile: "000000000",
      companyCategoryId: 4,
      companyFormId: 5,
      postalCode: "1111",
      integrationType: +values.integrationType,
    };

    dispatch(createCompany(data)).then(({ error }: ActionType) => {
      if (error?.message) {
        dispatch(showErrorMessage(error.message));
      } else {
        dispatch(fetchComanies())
        dispatch(showSuccessMessage(true));
        resetForm();
      }
    });
  };

  const addCompanySchema = Yup.object().shape({
    identificationCode: Yup.string().required(""),
    name: Yup.string().required(""),
    integrationType: Yup.string().required(""),
  });

  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Title>Add Company</Title>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={addCompanySchema}
          onSubmit={handleSubmit}
        >
          {({ values, isValid, dirty, setFieldValue }) => (
            <Form autoComplete="off">
              <Wrapper>
                <Input
                  id="identificationCode"
                  value={values.identificationCode}
                  setValue={(value: string) =>
                    setFieldValue("identificationCode", value)
                  }
                  label={t("CreateCompany.IdentificationCode")}
                />
                <Input
                  id="name"
                  value={values.name}
                  setValue={(value: string) => setFieldValue("name", value)}
                  label={t("CreateCompany.Name")}
                />
                <Select
                  selectedValue={values.integrationType.toString()}
                  label={t("CreateCompany.Integration")}
                  options={[
                    { label: "Oris5", value: "1" },
                    { label: "Oris7", value: "2" },
                    { label: "Balance", value: "3" },
                    { label: "Apricot", value: "4" },
                    { label: "Fina", value: "5" },
                  ]}
                  handleSelect={(value) => {
                    setFieldValue("integrationType", value);
                  }}
                />
                {/* <Input
               id={3}
               value={values.email}
               setValue={(value) => setFieldValue("email", value)}
               placeholder="Email"
             />


             <Input
               id={4}
               value={values.city}
               setValue={(value) => setFieldValue("city", value)}
               placeholder="City"
             />


             <Input
               id={5}
               value={values.address}
               setValue={(value) => setFieldValue("address", value)}
               placeholder="Address"
             />


             <Input
               id={6}
               value={values.mobileCountryCode}
               setValue={(value) => setFieldValue("mobileCountryCode", value)}
               placeholder="Country Mobile"
             />


             <Input
               id={7}
               value={values.mobile}
               setValue={(value) => setFieldValue("mobile", value)}
               placeholder="Mobile"
             />


             <Input
               id={8}
               value={`${values.companyCategoryId}`}
               setValue={(value) => setFieldValue("companyCategoryId", value)}
               placeholder="Company Category"
             />


             <Input
               id={9}
               value={`${values.companyFormId}`}
               setValue={(value) => setFieldValue("companyFormId", value)}
               placeholder="Company Form Id"
             />


             <Input
               id={10}
               value={values.postalCode}
               setValue={(value) => setFieldValue("postalCode", value)}
               placeholder="Postal Code"
             />


             <Input
               id={11}
               value={`${values.integrationType}`}
               setValue={(value) => setFieldValue("integrationType", value)}
               placeholder="Integration Type"
             /> */}
              </Wrapper>

              <Button
                loading={loading === "pending"}
                variant="filled"
                disabled={!isValid || !dirty}
                startIcon={
                  <Icon
                    iconName="plus_v2"
                    fill="white"
                    height={20}
                    width={20}
                  />
                }
                size="large"
                label="Add Company"
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default CreateCompany;
