import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { chartColors } from "../../../../../constants/color";
import { PeriodType } from "../../../../../enums/enum";
import {
    formatNumber,
    getUniquePeriods,
} from "../../../../../helpers/dashboardHelper";
import { formatDateByPeriod } from "../../../../../helpers/helper";
import { CashFlowRecord } from "../../../../../store/dashboard/dashboard.types";
import SearchInput from "../../../../shared/searchInput/SearchInput";
import {
    ColorBox,
    TableHeaderContainer,
    Header,
    InputWrapper,
    LeftSide,
    RightSide,
    SideTableTitleWrapper,
    Table,
    TableHeader,
    TableHeaderItem,
    Wrapper,
    TableList,
    TableListItem,
    NoDataWrapper,
    Content,
    NoDataTitle,
    NoDataSubTitle,
    TableHeaderTotalContainer,
    TableTotalListItem,
} from "./CashFlowTableChart.style";

import Icon from "../../../../shared/icon/Icon";
import {
    sumOfGroup,
} from "../../../../../helpers/profitLossHelper";

import { generateCashFlowTableData, generateCashFlowTableLabels, getCashUniqueLabels } from "../../../../../helpers/cashFlowHelper";

interface IProps {
    data: Array<CashFlowRecord>;
    periodType: PeriodType;
    identificator: 'id' | 'cashFlowCategory'
    handleClick?: (id: number) => void;
}



const CashFlowTableChart = ({ data, periodType, identificator, handleClick }: IProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const [hoverIndex, setHoverIndex] = useState(-1);
    const periods = getUniquePeriods(data);


    const uniqLabels = useMemo(() => {
        return getCashUniqueLabels(data, identificator)
    }, [data, identificator]);

    const tableLabels = useMemo(() => {
        if (!value) {
            return generateCashFlowTableLabels(data, identificator)
        }

        const filteredLabels = generateCashFlowTableLabels(data, identificator).filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        return filteredLabels
    }, [data, identificator, value])

    const tableList = useMemo(() => {
        if (!value) {
            generateCashFlowTableData(uniqLabels, data, identificator)
        }
        const filteredData = uniqLabels.filter((label) =>
            label.toLowerCase().includes(value.toLowerCase())
        );

        return generateCashFlowTableData(filteredData, data, identificator)
    }, [data, uniqLabels, identificator, value])




    const total = useMemo(() => {
        let sum = 0;
        tableList.forEach((group) => {
            group.forEach((item) => {
                const value = parseFloat(item);
                if (!isNaN(value)) {
                    sum += value;
                }
            });
        });

        return formatNumber(Number(sum.toFixed(1)));
    }, [tableList]);

    const calculateItemTotal = (index: number) => {
        let itemSum = 0;
        tableList.forEach((group) => {
            const value = parseFloat(group[index]);
            if (!isNaN(value)) {
                itemSum += value;
            }
        });

        return formatNumber(Number(itemSum.toFixed(1)));
    };

    const handleSearch = (value: string) => {
        setValue(value);
    };

    return (
        <Wrapper>
            <Header>
                <InputWrapper>
                    <SearchInput
                        smallPlaceholder
                        inputSize="small"
                        value={value}
                        placeholder={t("TableChart.SearchInputPlaceholder")}
                        handleChange={(e) => handleSearch(e.target.value)}
                    />
                </InputWrapper>
            </Header>
            {tableLabels.length ? (
                <Table>
                    <LeftSide>
                        <SideTableTitleWrapper isGrey>
                            <ColorBox size="small" />
                            <TableHeaderItem>{t("TableChart.GroupsTitle")}</TableHeaderItem>
                        </SideTableTitleWrapper>
                        <SideTableTitleWrapper>
                            <ColorBox />
                            <TableHeaderItem isListItem>
                                {t("TableChart.TotalItemTitle")}
                            </TableHeaderItem>
                        </SideTableTitleWrapper>
                        {tableLabels.map((label, index) => (
                            <SideTableTitleWrapper
                                key={index}
                                onClick={() => {
                                    handleClick && label.id && handleClick(label.id);
                                }}
                                hasRedirect={Boolean(handleClick)}
                                hasHover={hoverIndex === index}
                                onMouseEnter={() => {
                                    setHoverIndex(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverIndex(-1);
                                }}
                            >
                                <ColorBox color={chartColors[index]} />
                                <TableHeaderItem isListItem isBold>
                                    {label.name}
                                </TableHeaderItem>
                            </SideTableTitleWrapper>
                        ))}
                    </LeftSide>
                    <RightSide>
                        <TableHeader>
                            <TableHeaderTotalContainer>
                                {t("TableChart.TotalIncomeTitle")}
                            </TableHeaderTotalContainer>
                            {periods.map((date, index) => (
                                <TableHeaderContainer key={index}>
                                    {formatDateByPeriod(date, periodType)}
                                </TableHeaderContainer>
                            ))}
                        </TableHeader>

                        <TableList hasHover={false}>
                            <TableTotalListItem isBold>{total}</TableTotalListItem>
                            {tableList[0]?.map((_, itemIndex) => (
                                <TableListItem isBold key={itemIndex}>
                                    {calculateItemTotal(itemIndex)}
                                </TableListItem>
                            ))}
                        </TableList>

                        {tableList.map((group, groupIndex) => (
                            <TableList
                                key={groupIndex}
                                hasHover={groupIndex === hoverIndex}
                                onMouseEnter={() => {
                                    setHoverIndex(groupIndex);
                                }}
                                onMouseLeave={() => {
                                    setHoverIndex(-1);
                                }}
                            >
                                <TableTotalListItem isBold>
                                    {formatNumber(sumOfGroup(group))}
                                </TableTotalListItem>
                                {group.map((value, itemIndex) => (
                                    <TableListItem key={itemIndex}>
                                        {formatNumber(+value)}
                                    </TableListItem>
                                ))}
                            </TableList>
                        ))}
                    </RightSide>
                </Table>
            ) : (
                <NoDataWrapper>
                    <Icon iconName="noResult" width={64} height={64} />
                    <Content>
                        <NoDataTitle>{t("TableChart.NoDataTitle")}</NoDataTitle>
                        <NoDataSubTitle>{t("TableChart.NoDataSubTitle")}</NoDataSubTitle>
                    </Content>
                </NoDataWrapper>
            )}
        </Wrapper>
    );
};

export default CashFlowTableChart;
