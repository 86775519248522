import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import axiosInstance from "../../serveces/interceptor";
import config from "../../utils/config";

import {
  CheckEmailRequest,
  ChooseCompanyRequest,
  CreatePasswordRequest,
  InitResponse,
  InviteUserRequest,
  LoginRequest,
  ResetPasswordRequest,
  UpdateDefaultCompanyRequest,
  UpdateUserInfoRequest,
  UpdateUserRequest,
  User,
} from "./user.types";

export const LOGIN = "LOGIN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CREATE_PASSWORD = "CREATE_PASSWORD";

export const UPDATE_DEFAULT_COMPANY = "UPDATE_DEFAULT_COMPANY";
export const CHOOSE_COMPANY = "CHOOSE_COMPANY";
export const INIT = "INIT";

export const CREATE_USER = "CREATE_USER";
export const USER_LIST = "USER_LIST";
export const UPDATE_USER = "UPDATE_USER";
export const INVITE_USER = "INVITE_USER";
export const CHECK_EMAIL = "CHECK_EMAIL";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const FETCH_FAQ = "FETCH_FAQ";
export const FETCH_COMPANIES = "FETCH_COMPANIES";

export const login = createAsyncThunk(
  LOGIN,
  async ({ email, password }: LoginRequest) => {
    const response: AxiosResponse<User> = await axiosInstance.post(
      config.api.endpoints.auth.login,
      {
        email,
        password,
      }
    );

    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  RESET_PASSWORD,
  async ({ email }: ResetPasswordRequest) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.auth.resetPassword,
      {
        email,
      }
    );

    return response.data;
  }
);

export const createPassword = createAsyncThunk(
  CREATE_PASSWORD,
  async ({ password, token }: CreatePasswordRequest) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.auth.createPassword,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  }
);

export const updateDefaultCompany = createAsyncThunk(
  UPDATE_DEFAULT_COMPANY,
  async ({ defaultCompanyId }: UpdateDefaultCompanyRequest) => {
    await axiosInstance.put(config.api.endpoints.user.updateDefaultCompany, {
      defaultCompanyId,
    });
    return { defaultCompanyId };
  }
);

export const chooseCompany = createAsyncThunk(
  CHOOSE_COMPANY,
  async ({ CompanyId }: ChooseCompanyRequest) => {
    const response: AxiosResponse<User> = await axiosInstance.put(
      config.api.endpoints.auth.chooseCompany,
      {
        CompanyId,
      }
    );
    return response.data;
  }
);

export const init = createAsyncThunk(INIT, async () => {
  const response: AxiosResponse<InitResponse> = await axiosInstance.get(
    config.api.endpoints.init
  );

  return response.data;
});

export const createUser = createAsyncThunk(
  CREATE_USER,
  async ({ email }: ResetPasswordRequest) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.user.create,
      {
        email,
      }
    );

    return response.data;
  }
);

export const fetchUserList = createAsyncThunk(
  USER_LIST,
  async (data: {
    Name?: string;
    ClientRoleId?: number | null;
    Status?: number | null;
    page: number;
    pageSize: number;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.user.getList,
      {
        params: data,
      }
    );

    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  UPDATE_USER,
  async ({
    id,
    clientRoleId,
    status,
    notificationTypes,
    userId,
  }: UpdateUserRequest) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.user.clientUpdate,
      {
        id,
        clientRoleId,
        status,
        notificationTypes,
      }
    );
    return { clientCompany: response.data, userId };
  }
);

export const inviteUser = createAsyncThunk(
  INVITE_USER,
  async (data: InviteUserRequest) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.user.create,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const checkEmail = createAsyncThunk(
  CHECK_EMAIL,
  async (data: CheckEmailRequest) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.user.checkEmail,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const userInfoUpdate = createAsyncThunk(
  UPDATE_USER_INFO,
  async (data: UpdateUserInfoRequest) => {
    const response: AxiosResponse<User> = await axiosInstance.put(
      config.api.endpoints.user.userInfoUpdate,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const fetchFAQ = createAsyncThunk(FETCH_FAQ, async () => {
  const response: AxiosResponse = await axiosInstance.get(
    config.api.endpoints.faq.getList
  );

  return response.data;
});

export const fetchComanies = createAsyncThunk(FETCH_COMPANIES, async () => {
  const response: AxiosResponse = await axiosInstance.get(
    config.api.endpoints.user.companies
  );

  return response.data;
});


