import { PeriodType } from "../enums/enum";
import { NetIncomeResponseRecord } from "../store/dashboard/dashboard.types";
import { GridDataItem } from "./dashboardHelper";
import { formatDateByPeriod } from "./helper";


export const generateNetIncomeLabels = (data: NetIncomeResponseRecord[]) => {
    if (!data || !data.length) return [];

    const { profits, expenses } = data[0];

    const labels: string[] = [];

    if (profits.length) {
        const sortedProfits = [...profits].sort((a, b) => a.ordering - b.ordering);
        labels.push(...sortedProfits.map((item) => item.name));
    }
    if (expenses.length) {
        const sortedExpenses = [...expenses].sort((a, b) => a.ordering - b.ordering);
        labels.push(...sortedExpenses.map((item) => item.name));
    }

    return labels;
};

// todo need remove hard check
export const sumNetIncomeWithName = (
    array: Array<NetIncomeResponseRecord>,
    groupName: string
): number => {
    const isRevenue = groupName === "Revenue";

    const total = array.reduce((sum, item) => {
        const itemsToSum = isRevenue ? item.profits : item.expenses;
        return (
            sum +
            itemsToSum.reduce((innerSum, innerItem) => innerSum + innerItem.amount, 0)
        );
    }, 0);

    return Math.round(total * 10) / 10;
};

export const generateNetIncomeTableData = (
    labels: Array<string>,
    data: Array<NetIncomeResponseRecord>
): Array<Array<string>> => {
    return labels.map((label) => {
        return data
            .flatMap((record) => [...record.expenses, ...record.profits])
            .filter((item) => item.name === label)
            .map((item) => item.amount.toFixed(1));
    });
};

// todo need refactor
export function generateNetIncomeGridData(
    data: Array<NetIncomeResponseRecord>,
    periodType: PeriodType
): GridDataItem[] {
    const revenueData: Array<{
        id: number;
        period: string;
        NetIncome: number;
    }> = [];
    const expensesData: Array<{
        id: number;
        period: string;
        percentage: number;
    }> = [];

    data.forEach((item, index) => {
        const income = item.profits.reduce(
            (sum, profitItem) => sum + profitItem.amount,
            0
        );
        const outcome = item.expenses.reduce(
            (sum, profitItem) => sum + profitItem.amount,
            0
        );

        const percentage = ((income - outcome) / income) * 100;

        revenueData.push({
            id: index,
            period: formatDateByPeriod(item.period, +periodType),
            NetIncome: income - outcome,
        });

        expensesData.push({
            id: index,
            period: formatDateByPeriod(item.period, +periodType),
            percentage:
                typeof percentage !== "number" ||
                    isNaN(percentage) ||
                    percentage === Infinity ||
                    percentage === -Infinity
                    ? 0
                    : percentage,
        });
    });

    return [
        { data: revenueData, label: "Net Income", title: "NetIncome", id: 1 },
        {
            data: expensesData,
            percentage: true,
            label: "Net Income Percentage",
            title: "percentage",
            id: 2,
        },
    ];
}

export const generateNetIncomeStackChartData = (
    data: NetIncomeResponseRecord[],
    periodType: PeriodType
) => {
    return data.map((item) => {
        const data: { [key: string]: number | string } = {
            period: formatDateByPeriod(item.period, periodType),
        };

        [...item.expenses, ...item.profits].forEach((entry) => {
            data[entry.name] = entry.amount;
        });

        return data;
    });
};
