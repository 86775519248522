import { useMemo } from "react";
import { chartColors } from "../../../../../constants/color";
import { getCashUniqueLabels } from "../../../../../helpers/cashFlowHelper";

import {
  ColorBox,
  ListItem,
  ListItemWrapper,
  Wrapper,
} from "./CashFlowBarChartLabels.style";
import { CashFlowRecord } from "../../../../../store/dashboard/dashboard.types";



interface IProps {
  data: CashFlowRecord[]
  identificator: 'id' | 'cashFlowCategory'
}

const CashFlowBarChartLabels = ({ data, identificator }: IProps) => {

  const uniqLabels = useMemo(() => {
    return getCashUniqueLabels(data, identificator)
  }, [data, identificator]);

  return (
    <Wrapper>
      {uniqLabels.map((itemName, index) => (
        <ListItemWrapper key={index}>
          <ColorBox color={chartColors[index]} />
          <ListItem>{itemName}</ListItem>
        </ListItemWrapper>
      ))}
    </Wrapper>
  );
};

export default CashFlowBarChartLabels;
