import React, { useMemo } from "react";
import { CardTypes, ChartMode, PeriodType } from "../../../../../enums/enum";
import {
    formatNumber,

    GridDataItem,

} from "../../../../../helpers/dashboardHelper";
import { CashFlowRecord } from "../../../../../store/dashboard/dashboard.types";
import DashboardChartCard from "../../../../shared/card/Card";
import GroupItemBarChart from "../../groupItemChart/GroupItemBarChart";
import { ChartContainer, GridContainer } from "./CashFlowGrid.style";
import { generateCashGridData, generateNetCashGridData, sumGroupData } from "../../../../../helpers/cashFlowHelper";
import { CashFlowCategoryType, CashFlowDirectionType } from "../../../../../store/dashboard/dahsboard.enums";

interface IProps {
    data: Array<CashFlowRecord>;
    periodType: PeriodType;
    direction: CashFlowDirectionType | undefined
    identificator: 'id' | 'cashFlowCategory'
    handleViewDetail?: (id: number | string) => void;
}

const CashFlowGrid = ({ data, periodType, identificator, direction, handleViewDetail }: IProps) => {
    const gridData = useMemo(
        () => {
            if (direction) { return generateCashGridData(data, periodType, identificator); }
            return generateNetCashGridData(data, periodType, identificator)
        },
        [data, periodType, identificator, direction]
    );


    const shouldShow = (item: GridDataItem): boolean => {
        return Boolean(
            item.data &&
            item.data.length > 0 &&
            !item.data.every((el) => el[item.title] === 0)
        );
    };

    return (
        <GridContainer>
            {gridData.map((gridItem, index) => {
                if (shouldShow(gridItem))
                    return (
                        <DashboardChartCard
                            key={index}
                            selectedPeriodType={periodType}
                            selectedViewType={ChartMode.Grid}
                            type={CardTypes.SMALL}
                            title={gridItem.title || ""}
                            value={formatNumber(sumGroupData(data, gridItem.title as keyof typeof CashFlowCategoryType, direction, identificator))}
                            handleViewDetail={handleViewDetail ? () => { handleViewDetail(gridItem.id) } : undefined}
                        >
                            <ChartContainer>
                                <GroupItemBarChart
                                    title={gridItem.title}
                                    colorIndex={index}
                                    data={gridItem.data}
                                />
                            </ChartContainer>
                        </DashboardChartCard>
                    );
                return null;
            })}
        </GridContainer >
    );
};

export default CashFlowGrid;
