export enum CashFlowDirectionType {
  Received = 1,
  Paid = 2,
}

export enum CashFlowCategoryType {
  None = 0,
  Operating = 1,
  Investment = 2,
  Financing = 3,
}

export const CashFlowCategoryTypeWithName: Record<
  CashFlowCategoryType,
  string
> = {
  [CashFlowCategoryType.None]: "None",
  [CashFlowCategoryType.Operating]: "Operating",
  [CashFlowCategoryType.Investment]: "Investment",
  [CashFlowCategoryType.Financing]: "Financing",
};


export const CashFlowCategoryTypeOrdering = {
  '1': 1,
  '2': 2,
  '3': 3,
  '0': 4,
}

