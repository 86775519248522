import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  getCashFlowByGroup,
} from "../../../../store/dashboard/dashboardAction";
import {
  Wrapper,
  LastUpdated,
  Header,
  Title,
  TitleWrapper,
  UpdateDateSkeleton,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  BarChartTitleContainer,
  SkeletonWrapper,
  TreeChartContainer,
} from "./CashIn.style";

import {
  dashboardDateFormat,
  generateGridChartModes,
} from "../../../../helpers/helper";
import {
  CardTypes,
  CashFlowType,
  ChartMode,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";

import { useTranslation } from "react-i18next";

import Filters from "../../../../components/shared/dashboard/filters/Filters";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import DashboardChartCard from "../../../../components/shared/card/Card";

import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";

import { getRange365Days } from "../../../../helpers/dateHelper";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import moment from "moment";
import {
  formatNumber,
} from "../../../../helpers/dashboardHelper";
import CashFlowGroupBar from "../../../../components/desktop/charts/cashFlow/cashFlowGroupBar/CashFlowGroupBar";
import CashFlowBarChartLabels from "../../../../components/desktop/charts/cashFlow/cashFlowBarChartLabels/CashFlowBarChartLabels";
import { sumCashData } from "../../../../helpers/cashFlowHelper";
import CashFlowGrid from "../../../../components/desktop/charts/cashFlow/cashFlowGrid/CashFlowGrid";
import { CashFlowDirectionType } from "../../../../store/dashboard/dahsboard.enums";
import CashFlowTableChart from "../../../../components/desktop/charts/cashFlow/cashFlowTableChart/CashFlowTableChart";
import { useNavigate } from "react-router-dom";
import CashFlowTreeChart from "../../../../components/desktop/charts/cashFlow/cashFlowTreeChart/CashFlowTreeChart";

const initialFilterData = {
  date: {
    type: RangeType.Past365Days,
    range: getRange365Days(),
  },
  period: PeriodType.Month,
  groups: [],
  branches: [],
  businessLines: [],
};

const CashIn = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isShowClearAll, setIsShowClearAll] = useState(false);
  const [cardViewMode, setCardViewMode] = useState<ChartMode>(
    ChartMode.Stacked
  );
  const [selectedDateRange, setSelectedDateRange] = useState(
    initialFilterData.date
  );
  const [selectedPeriodType, setSelectedPeriodType] = useState(
    initialFilterData.period
  );
  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);

  const cashInData = useAppSelector((state) => state.dashboard.cashInData);

  const cashInLoading = useAppSelector(
    (state) => state.dashboard.cashInLoading
  );

  const hasNoData = useMemo(() => {
    return cashInData.data.every((item) => item.amount === 0);
  }, [cashInData]);

  const totalSum = useMemo(() => {
    return formatNumber(sumCashData(cashInData.data));
  }, [cashInData]);

  useEffect(() => {
    dispatch(
      getCashFlowByGroup({
        periodType: PeriodType.Month,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
        chartCashFlowRequestType: CashFlowType.CashIn,
      })
    );
  }, [dispatch]);

  const handleClearAll = () => {
    getCashFlowByGroup({
      periodType: PeriodType.Month,
      fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
      toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
      chartCashFlowRequestType: CashFlowType.CashIn,
    });
  };

  // const handleDownload = () => {
  //   const data: FinancialGroupsRequest = {
  //     periodType: selectedPeriodType,
  //     fromDate: moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
  //     toDate: moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
  //     chartType: selectedChartType,
  //     companyBranchIds: selectedBranches,
  //     companyBusinessLineIds: selectedBusinessLine,
  //     accountMappingGroupIds: selectedGroups,
  //   };
  //   dispatch(getRevenueGroupsExcel(data))
  //     .then((action: ActionType) => {
  //       if (action.error) {
  //         console.error("Download failed:", action.error);
  //       } else if (action?.payload) {
  //         downloadFile(action?.payload);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Unexpected error during download:", error);
  //     });
  // };

  const handleFilter = ({
    periodType,
    dateRange,
    dateType,
  }: {
    periodType?: PeriodType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    let data = {
      periodType: periodType || selectedPeriodType,
      fromDate: dateRange?.length
        ? dateRange[0]
        : moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
      toDate: dateRange?.length
        ? dateRange[1]
        : moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
      chartCashFlowRequestType: CashFlowType.CashIn,
    };

    dispatch(getCashFlowByGroup(data));
  };

  const handleNavigationGroupDetail = (groupId: number | string) => {
    if (groupId) {
      navigate(groupId.toString());
    }
  };

  if (isMobile) return <div>under construction</div>;

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("CashIn.Title")}</Title>
          {cashInData.lastUpdateTime ? (
            <LastUpdated>
              {t("CashIn.DateUpdatedTitle")}:{" "}
              {dashboardDateFormat(cashInData.lastUpdateTime)}
            </LastUpdated>
          ) : (
            <UpdateDateSkeleton />
          )}
        </TitleWrapper>

        <Filters
          isShowClearAll={isShowClearAll}
          groupOptions={[]}
          selectedDateRange={selectedDateRange}
          selectedBranches={[]}
          selectedGroups={[]}
          isLoading={false}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            setSelectedDateRange(dateData);
            handleFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            setSelectedDateRange(initialFilterData.date);
            handleClearAll();
          }}
        // handleDownload={handleDownload}
        />
      </Header>

      {cashInLoading === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={selectedPeriodType}
                selectedViewType={cardViewMode}
                type={CardTypes.LARGE}
                title={t("CashIn.DashboardCardTitle")}
                value={totalSum}
                handlePeriodType={(periodType) => {
                  setSelectedPeriodType(periodType);
                  handleFilter({ periodType });
                }}
                handleViewMode={setCardViewMode}
              >
                {cardViewMode === ChartMode.Stacked && <>
                  <CashFlowBarChartLabels
                    data={cashInData.data}
                    identificator="cashFlowCategory" />
                  <GroupBarChartContainer >
                    <CashFlowGroupBar
                      identificator="cashFlowCategory"
                      data={cashInData.data}
                      periodType={selectedPeriodType}
                      direction={CashFlowDirectionType.Received}
                    />
                  </GroupBarChartContainer></>}

                {cardViewMode === ChartMode.Tree && (
                  <TreeChartContainer>
                    <CashFlowTreeChart
                      identificator="cashFlowCategory"
                      data={cashInData.data}
                      direction={CashFlowDirectionType.Received}
                    />
                  </TreeChartContainer>
                )}
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("CashIn.GroupChartTitle")}</Title>
                  <ChartTypeSwitcher
                    viewOptions={generateGridChartModes()}
                    selectedViewMode={viewMode}
                    handleViewMode={setViewMode}
                  />
                </BarChartTitleContainer>
                {viewMode === ChartMode.Grid && (
                  <CashFlowGrid
                    handleViewDetail={
                      handleNavigationGroupDetail
                    }
                    identificator="cashFlowCategory"
                    periodType={selectedPeriodType}
                    data={cashInData.data}
                    direction={CashFlowDirectionType.Received}
                  />
                )}
                {viewMode === ChartMode.Table && (
                  <CashFlowTableChart
                    handleClick={
                      handleNavigationGroupDetail
                    }
                    identificator="cashFlowCategory"
                    periodType={selectedPeriodType}
                    data={cashInData.data}
                  />
                )}
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CashIn;
