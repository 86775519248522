import { PeriodType } from "../enums/enum";
import {
  CashFlowCategoryType,
  CashFlowCategoryTypeOrdering,
  CashFlowCategoryTypeWithName,
  CashFlowDirectionType,
} from "../store/dashboard/dahsboard.enums";
import { CashFlowRecord } from "../store/dashboard/dashboard.types";
import { GridDataItem } from "./dashboardHelper";
import { formatDateByPeriod } from "./helper";


export const getCashUniqueLabels = (data: Array<CashFlowRecord>, key: 'cashFlowCategory' | 'id'): string[] => {
  return Array.from(
    new Map(
      data.map((item) => {
        const label = key === 'cashFlowCategory'
          ? CashFlowCategoryTypeWithName[item[key]] ?? ''
          : String(item[key]);

        const order = key === 'cashFlowCategory'
          ? CashFlowCategoryTypeOrdering[item[key]] ?? Number.MAX_SAFE_INTEGER
          : 1;

        return [label, order];
      })
    ).entries()
  )
    .sort((a, b) => a[1] - b[1])
    .map(([name]) => name);
};



export const sumCashData = (array: Array<CashFlowRecord>): number => {
  return Number(
    array
      .reduce((sum, item) => sum + item.amount, 0)
      .toFixed(1)
  );
};


export const sumNetCashData = (array: Array<CashFlowRecord>): number => {
  return Number(
    array
      .reduce((sum, item) => item.cashFlowDirection === CashFlowDirectionType.Received ? sum + item.amount : sum - item.amount, 0)
      .toFixed(1)
  );
};



export const sumGroupData = (array: Array<CashFlowRecord>, groupName: keyof typeof CashFlowCategoryType | string, direction: CashFlowDirectionType | undefined, identificator: 'id' | 'cashFlowCategory'
): number => {
  if (direction) {
    return Number(
      array
        .reduce((sum, item) => {
          const name = identificator === 'cashFlowCategory' ? item.cashFlowCategory : item.id || '';
          const condition = identificator === 'cashFlowCategory' ? name === CashFlowCategoryType[groupName as keyof typeof CashFlowCategoryType] : name === groupName
          if (condition) {
            return sum + item.amount
          }
          return sum;
        }, 0)
        .toFixed(1)
    );
  }


  return Number(
    array
      .reduce((sum, item) => {
        const name = identificator === 'cashFlowCategory' ? item.cashFlowCategory : item.id || '';
        const condition = identificator === 'cashFlowCategory' ? name === CashFlowCategoryType[groupName as keyof typeof CashFlowCategoryType] : name === groupName
        if (condition) {
          return item.cashFlowDirection === CashFlowDirectionType.Received
            ? sum + item.amount
            : sum - item.amount;
        }
        return sum;
      }, 0)
      .toFixed(1)
  );
};


export const generateCashTreeChartData = (
  data: Array<CashFlowRecord>,
  colors: Array<string>,
  identificator: 'id' | 'cashFlowCategory'
): Array<{ name: string; value: number; netValue: number; color: string }> => {
  const totalSum = data.reduce((total, item) => total + item.amount, 0);

  // Summarize data by mappingGroupName with their total sum
  const summary = data.reduce<
    Record<string, { value: number; ordering: number }>
  >((acc, { cashFlowCategory, id, amount }) => {
    const name = identificator === 'cashFlowCategory' ?
      CashFlowCategoryTypeWithName[
      cashFlowCategory as CashFlowCategoryType
      ] : id || '';

    const ordering = identificator === 'cashFlowCategory' ? CashFlowCategoryTypeOrdering[cashFlowCategory] : 1;

    if (!acc[name]) {
      acc[name] = {
        value: 0,
        ordering: ordering,
      };
    }
    acc[name].value += amount;
    return acc;
  }, {});


  // Convert to array, sort by ordering, and map to final output
  return Object.entries(summary)
    .sort(([, a], [, b]) => a.ordering - b.ordering) // Sort by accountMappingGroupOrdering
    .map(([name, { value }], index) => {
      const percentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
      return {
        name,
        value: parseFloat(percentage.toFixed(2)) || 0,
        netValue: parseFloat(value.toFixed(2)),
        color: colors[index % colors.length], // Handle cases where colors array is shorter
      };
    });
};



export const generateNetCashTreeChartData = (
  data: Array<CashFlowRecord>,
  colors: Array<string>,
  identificator: 'id' | 'cashFlowCategory'
): Array<{ name: string; value: number; netValue: number; color: string }> => {
  const totalSum = data.reduce((total, item) => total + item.amount, 0);

  // Summarize data by mappingGroupName with their total sum
  const summary = data.reduce<
    Record<string, { value: number; ordering: number }>
  >((acc, { cashFlowCategory, cashFlowDirection, id, amount }) => {
    const name = identificator === 'cashFlowCategory' ?
      CashFlowCategoryTypeWithName[
      cashFlowCategory as CashFlowCategoryType
      ] : id || '';

    const ordering = identificator === 'cashFlowCategory' ? CashFlowCategoryTypeOrdering[cashFlowCategory] : 1;

    if (!acc[name]) {
      acc[name] = {
        value: 0,
        ordering: ordering,
      };
    }


    if (cashFlowDirection === CashFlowDirectionType.Paid) {
      acc[name].value -= amount;
    }


    if (cashFlowDirection === CashFlowDirectionType.Received) {
      acc[name].value += amount;
    }

    return acc;
  }, {});



  // Convert to array, sort by ordering, and map to final output
  return Object.entries(summary)
    .sort(([, a], [, b]) => a.ordering - b.ordering) // Sort by accountMappingGroupOrdering
    .map(([name, { value }], index) => {
      const percentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
      return {
        name,
        value: parseFloat(percentage.toFixed(2)) || 0,
        netValue: parseFloat(value.toFixed(2)),
        color: colors[index % colors.length], // Handle cases where colors array is shorter
      };
    });
};



type OutputData = {
  period: string;
} & {
  [key: string]: number | string;
};

export const generateCashData = (
  data: Array<CashFlowRecord>,
  periodType: PeriodType,
  identificator: 'id' | 'cashFlowCategory'
) => {
  const grouped = data.reduce<Record<string, OutputData>>((acc, item) => {
    const { period, amount } = item;
    const name = identificator === 'cashFlowCategory' ?
      CashFlowCategoryTypeWithName[
      item.cashFlowCategory as CashFlowCategoryType
      ] : item.id || '';
    const formatPeriod = formatDateByPeriod(period, periodType);
    if (!acc[formatPeriod]) {
      acc[formatPeriod] = { period: formatPeriod };
    }

    acc[formatPeriod][name] = amount;
    return acc;
  }, {});

  return Object.values(grouped);
};

export const generateNetCashData = (
  data: Array<CashFlowRecord>,
  periodType: PeriodType,
  identificator: 'id' | 'cashFlowCategory'

) => {
  const grouped = data.reduce<Record<string, OutputData>>((acc, item) => {
    const { period, amount, cashFlowDirection } = item;

    const name = identificator === 'cashFlowCategory' ?
      CashFlowCategoryTypeWithName[
      item.cashFlowCategory as CashFlowCategoryType
      ] : item.id?.toString() || '';

    const formatPeriod = formatDateByPeriod(period, periodType);

    if (!acc[formatPeriod]) {
      acc[formatPeriod] = { period: formatPeriod };
    }

    if (!acc[formatPeriod]) {
      acc[formatPeriod] = { period: formatPeriod };
    }

    acc[formatPeriod][name] = (acc[formatPeriod][name] ?? 0)

    if (cashFlowDirection === CashFlowDirectionType.Paid) {
      acc[formatPeriod][name] = Number(acc[formatPeriod][name]) - Number(amount);
    }

    if (cashFlowDirection === CashFlowDirectionType.Received) {
      acc[formatPeriod][name] = Number(acc[formatPeriod][name]) + Number(amount);
    }


    return acc;
  }, {});


  return Object.values(grouped);
};





export function generateCashGridData(
  data: Array<CashFlowRecord>,
  periodType: PeriodType,
  identificator: 'id' | 'cashFlowCategory'

): GridDataItem[] {
  const groupedData: Record<
    string,
    { [key: string]: number | string; period: string }[]
  > = {};

  [...data].sort((a, b) => CashFlowCategoryTypeOrdering[a.cashFlowCategory] - CashFlowCategoryTypeOrdering[b.cashFlowCategory]).forEach((item) => {
    const { cashFlowCategory, amount, period } = item;
    const name = identificator === 'cashFlowCategory' ? CashFlowCategoryTypeWithName[cashFlowCategory] : item.id || ''
    if (!groupedData[name]) {
      groupedData[name] = [];
    }
    groupedData[name].push({
      [name]: amount,
      period: formatDateByPeriod(period, +periodType),
      id: item.cashFlowCategory
    });
  });

  return Object.entries(groupedData).map(([title, data]) => ({
    title,
    data,
    id: data[0]?.id || 0,
  }));

}


export function generateNetCashGridData(
  data: Array<CashFlowRecord>,
  periodType: PeriodType,
  identificator: 'id' | 'cashFlowCategory'
): GridDataItem[] {
  const groupedData: Record<
    string,
    { [key: string]: number | string; period: string }[]
  > = {};

  [...data].sort((a, b) => CashFlowCategoryTypeOrdering[a.cashFlowCategory] - CashFlowCategoryTypeOrdering[b.cashFlowCategory]).forEach((item) => {
    const { cashFlowCategory, cashFlowDirection, amount, period } = item;
    const categoryName = identificator === 'cashFlowCategory' ? CashFlowCategoryTypeWithName[cashFlowCategory] : item.id || '';
    const formattedPeriod = formatDateByPeriod(period, periodType);

    if (!groupedData[categoryName]) {
      groupedData[categoryName] = [];
    }

    const existingEntry = groupedData[categoryName].find(
      (entry) => entry.period === formattedPeriod
    );

    if (!existingEntry) {
      groupedData[categoryName].push({
        period: formattedPeriod,
        id: item.cashFlowCategory,
        [categoryName]: 0,
      });
    }

    const entry = groupedData[categoryName].find(
      (entry) => entry.period === formattedPeriod
    );

    if (entry) {
      if (cashFlowDirection === CashFlowDirectionType.Paid) {
        entry[categoryName] = (entry[categoryName] as number) - amount;
      }
      if (cashFlowDirection === CashFlowDirectionType.Received) {
        entry[categoryName] = (entry[categoryName] as number) + amount;
      }
    }
  });

  return Object.entries(groupedData).map(([title, data]) => ({
    title,
    data,
    id: data[0]?.id || 0,
  }));
}



export const generateCashFlowTableLabels = (
  items: Array<CashFlowRecord>,
  identificator: 'id' | 'cashFlowCategory'
): Array<{ id: number; name: string }> => {
  return Array.from(
    new Map(items.map((item) => [identificator === 'cashFlowCategory' ? CashFlowCategoryTypeWithName[item.cashFlowCategory] : item.id?.toString() || '', [identificator === 'cashFlowCategory' ? item.cashFlowCategory : item.id?.toString() || '', CashFlowCategoryTypeOrdering[item.cashFlowCategory]]])).entries()
  )
    .sort((a, b) =>
      Number(a[1][1]) - Number(b[1][1])
    )
    .map(([name, data]) => {
      return {
        name,
        id: +data[0],
      }
    });
};


export const generateNetCashFlowTableLabels = (
  items: Array<CashFlowRecord>,
  identificator: 'id' | 'cashFlowCategory'
): Array<{ id: number; name: string }> => {
  return Array.from(
    new Map(items.map((item) => [identificator === 'cashFlowCategory' ? CashFlowCategoryTypeWithName[item.cashFlowCategory] : item.id?.toString() || '', [item.cashFlowCategory, CashFlowCategoryTypeOrdering[item.cashFlowCategory]]])).entries()
  )
    .sort((a, b) =>
      Number(a[1][1]) - Number(b[1][1])
    )
    .map(([name, data]) => {
      return {
        name,
        id: +data[0],
      }
    });
};



export const generateCashFlowTableData = (
  labels: Array<string>,
  data: Array<CashFlowRecord>,
  identificator: 'id' | 'cashFlowCategory'
): Array<Array<string>> => {
  return labels.map((label) => {
    return data
      .filter((dataItem) => { const name = identificator === 'cashFlowCategory' ? CashFlowCategoryTypeWithName[dataItem.cashFlowCategory] : dataItem.id || ''; return name === label })
      .map((dataItem) => dataItem.amount.toFixed(1));
  });
};



type AggregatedData = {
  period: string;
  cashFlowCategory: string | CashFlowCategoryType;
  amount: number;
};

export const generateNetCashFlowTableData = (labels: Array<string>, data: CashFlowRecord[], identificator: 'id' | 'cashFlowCategory'
) => {
  const resultMap = new Map<string, AggregatedData>();

  data.forEach(({ period, amount, cashFlowDirection, cashFlowCategory, id }) => {
    const key = `${period}-${identificator === 'cashFlowCategory' ? cashFlowCategory : id || ''
      }`;
    const sign = cashFlowDirection === CashFlowDirectionType.Received ? 1 : -1;

    if (!resultMap.has(key)) {
      const name = identificator === 'cashFlowCategory' ? cashFlowCategory : id?.toString() || '';
      resultMap.set(key, { period, cashFlowCategory: name, amount: 0 });
    }

    resultMap.get(key)!.amount += amount * sign;
  });

  const groupedData = Array.from(resultMap.values());


  return labels.map((label) => {
    return [...groupedData]
      .filter((dataItem: AggregatedData) => {
        const name = identificator === 'cashFlowCategory' ? CashFlowCategoryTypeWithName[dataItem.cashFlowCategory as CashFlowCategoryType] : dataItem.cashFlowCategory;
        return name === label
      })
      .map((dataItem: AggregatedData) => dataItem.amount.toFixed(1));
  });
};

