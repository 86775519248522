import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { getCashFlowCategoryTypeId } from "../../../../store/dashboard/dashboardAction";
import {
    Wrapper,
    Header,
    Title,
    TitleWrapper,
    GroupBarChartContainer,
    GroupDetailChartWrapper,
    BarChartTitleContainer,
    SkeletonWrapper,
    NavLink,
    TreeChartContainer,
} from "./NetCashByTypeId.style";
import {
    generateGridChartModes,
} from "../../../../helpers/helper";
import {
    CardTypes,
    CashFlowType,
    ChartMode,
    PeriodType,
    RangeType,
} from "../../../../enums/enum";

import { useTranslation } from "react-i18next";

import Filters from "../../../../components/shared/dashboard/filters/Filters";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import DashboardChartCard from "../../../../components/shared/card/Card";

import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";

import { getRange365Days } from "../../../../helpers/dateHelper";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import moment from "moment";
import {
    formatNumber,
} from "../../../../helpers/dashboardHelper";

import { sumNetCashData } from "../../../../helpers/cashFlowHelper";

import CashFlowBarChartLabels from "../../../../components/desktop/charts/cashFlow/cashFlowBarChartLabels/CashFlowBarChartLabels";
import CashFlowGroupBar from "../../../../components/desktop/charts/cashFlow/cashFlowGroupBar/CashFlowGroupBar";
import CashFlowGrid from "../../../../components/desktop/charts/cashFlow/cashFlowGrid/CashFlowGrid";

import NetCashFlowTableChart from "../../../../components/desktop/charts/cashFlow/netCashFlowTableChart/NetCashFlowTableChart";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "../../../../components/shared/icon/Icon";
import { PageURL } from "../../../../constants/router";
import CashFlowTreeChart from "../../../../components/desktop/charts/cashFlow/cashFlowTreeChart/CashFlowTreeChart";

const initialFilterData = {
    date: {
        type: RangeType.Past365Days,
        range: getRange365Days(),
    },
    period: PeriodType.Month,
    groups: [],
    branches: [],
    businessLines: [],
};

const NetCashByTypeId = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let { typeId } = useParams();

    const [isShowClearAll, setIsShowClearAll] = useState(false);

    const [selectedDateRange, setSelectedDateRange] = useState(
        initialFilterData.date
    );
    const [selectedPeriodType, setSelectedPeriodType] = useState(
        initialFilterData.period
    );

    const [cardViewMode, setCardViewMode] = useState<ChartMode>(
        ChartMode.Stacked
    );
    const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);

    const netCashData = useAppSelector((state) => state.dashboard.netCashByTypeData);

    const netCashLoading = useAppSelector(
        (state) => state.dashboard.netCashByTypeLoading
    );

    const hasNoData = useMemo(() => {
        return netCashData.data.every((item) => item.amount === 0);
    }, [netCashData]);

    const totalSum = useMemo(() => {
        return formatNumber(sumNetCashData(netCashData.data));
    }, [netCashData]);

    useEffect(() => {
        if (!typeId) return;
        dispatch(
            getCashFlowCategoryTypeId({
                cashFlowCategoryTypeId: +typeId,
                periodType: PeriodType.Month,
                fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
                toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
                chartCashFlowRequestType: CashFlowType.NetCash,
            })
        );
    }, [dispatch, typeId]);

    const handleClearAll = () => {
        if (!typeId) return;
        getCashFlowCategoryTypeId({
            cashFlowCategoryTypeId: +typeId,
            periodType: PeriodType.Month,
            fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
            toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
            chartCashFlowRequestType: CashFlowType.NetCash,
        });
    };
    // const handleDownload = () => {
    //   const data: FinancialGroupsRequest = {
    //     periodType: selectedPeriodType,
    //     fromDate: moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
    //     toDate: moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
    //     chartType: selectedChartType,
    //     companyBranchIds: selectedBranches,
    //     companyBusinessLineIds: selectedBusinessLine,
    //     accountMappingGroupIds: selectedGroups,
    //   };
    //   dispatch(getRevenueGroupsExcel(data))
    //     .then((action: ActionType) => {
    //       if (action.error) {
    //         console.error("Download failed:", action.error);
    //       } else if (action?.payload) {
    //         downloadFile(action?.payload);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Unexpected error during download:", error);
    //     });
    // };

    const handleFilter = ({
        periodType,
        dateRange,
        dateType,
    }: {
        periodType?: PeriodType;
        dateRange?: Array<string>;
        dateType?: RangeType;
    }) => {
        if (!typeId) return;
        let data = {
            cashFlowCategoryTypeId: +typeId,
            periodType: periodType || selectedPeriodType,
            fromDate: dateRange?.length
                ? dateRange[0]
                : moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
            toDate: dateRange?.length
                ? dateRange[1]
                : moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
            chartCashFlowRequestType: CashFlowType.NetCash,
        };

        dispatch(getCashFlowCategoryTypeId(data));
    };


    const handleNavigationGroupDetail = (groupId: number | string) => {
        if (groupId) {
            navigate(groupId.toString());
        }
    };


    if (isMobile) return <div>under construction</div>;

    return (
        <Wrapper>
            <Header>
                <TitleWrapper>
                    <Title>{t("NetCashByType.Title")}</Title>
                    <NavLink>
                        <Link to={PageURL.NetCash.path}>
                            <Icon iconName="leftArrow" height={12} width={7} />
                            <span> {t("NetCashByType.SubTitle")}</span>
                        </Link>
                    </NavLink>
                </TitleWrapper>

                <Filters
                    isShowClearAll={isShowClearAll}
                    groupOptions={[]}
                    selectedDateRange={selectedDateRange}
                    selectedBranches={[]}
                    selectedGroups={[]}
                    isLoading={false}
                    handleDateRange={(dateData) => {
                        setIsShowClearAll(true);
                        setSelectedDateRange(dateData);
                        handleFilter({
                            dateRange: dateData.range,
                            dateType: dateData.type,
                        });
                    }}
                    handleClearAll={() => {
                        setIsShowClearAll(false);
                        setSelectedDateRange(initialFilterData.date);
                        handleClearAll();
                    }}
                // handleDownload={handleDownload}
                />
            </Header>

            {netCashLoading === "pending" ? (
                <SkeletonWrapper>
                    <BigCardSkeleton />
                    <CardGroupsSkeleton />
                </SkeletonWrapper>
            ) : (
                <>
                    {hasNoData ? (
                        <EmptyDashboard />
                    ) : (
                        <>
                            <DashboardChartCard
                                selectedPeriodType={selectedPeriodType}
                                selectedViewType={cardViewMode}
                                type={CardTypes.LARGE}
                                title={t("NetCashByType.DashboardCardTitle")}
                                value={totalSum}
                                handlePeriodType={(periodType) => {
                                    setSelectedPeriodType(periodType);
                                    handleFilter({ periodType });
                                }}
                                handleViewMode={setCardViewMode}

                            >
                                {cardViewMode === ChartMode.Stacked && <>
                                    <CashFlowBarChartLabels
                                        identificator="id"
                                        data={netCashData.data} />
                                    <GroupBarChartContainer>
                                        <CashFlowGroupBar
                                            identificator="id"
                                            data={netCashData.data}
                                            periodType={selectedPeriodType}
                                            direction={undefined}
                                        />
                                    </GroupBarChartContainer>
                                </>}
                                {cardViewMode === ChartMode.Tree && (
                                    <TreeChartContainer>
                                        <CashFlowTreeChart
                                            identificator="id"
                                            data={netCashData.data}
                                            direction={undefined}
                                        />
                                    </TreeChartContainer>
                                )}
                            </DashboardChartCard>
                            <GroupDetailChartWrapper>
                                <BarChartTitleContainer>
                                    <Title>{t("NetCashByType.GroupChartTitle")}</Title>
                                    <ChartTypeSwitcher
                                        viewOptions={generateGridChartModes()}
                                        selectedViewMode={viewMode}
                                        handleViewMode={setViewMode}
                                    />
                                </BarChartTitleContainer>
                                {viewMode === ChartMode.Grid && (
                                    <CashFlowGrid
                                        periodType={selectedPeriodType}
                                        identificator="id"
                                        data={netCashData.data}
                                        direction={undefined}
                                        handleViewDetail={handleNavigationGroupDetail}

                                    />
                                )}
                                {viewMode === ChartMode.Table && (
                                    <NetCashFlowTableChart
                                        identificator="id"
                                        periodType={selectedPeriodType}
                                        data={netCashData.data}
                                        handleClick={handleNavigationGroupDetail}
                                    />
                                )}
                            </GroupDetailChartWrapper>
                        </>
                    )}
                </>
            )}
        </Wrapper>
    );
}

export default NetCashByTypeId
